import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Layout from "../components/layout/MainLayout";
import { NetlifyForm } from "../components/NetlifyForm";

export const Heading1 = styled.h1`
  color: #94441e;
  font-weight: bold;
`;

export const ColWrap = styled(Col)`
  padding: 3vh 4vw;
`;

const FormWrap = styled.div`
  input,
  textarea {
    margin: 0.25rem 0;
  }
  label {
    display: none;
  }
  .form-group {
    label {
      display: block;
    }
  }
  button {
    margin: 0.25rem 0;
  }
`;

const ContactPage = () => {
  return (
    <Layout>
      <Container>
        <Row>
          <ColWrap>
            <Heading1>Contact Us About Water4Otter</Heading1>
            <FormWrap>
              <NetlifyForm
                fields={[
                  { as: `name` },
                  {
                    as: `single`,
                    props: {
                      name: `orgCompany`,
                      label: `Organization/Company`
                    }
                  },
                  { as: `email` },
                  { as: `phone` },
                  {
                    as: `radio`,
                    props: {
                      name: "contactPreference",
                      label: `Contact Preference`,
                      options: [{ label: `By Email` }, { label: `By Phone` }]
                    }
                  },
                  { as: "message" }
                ]}
                config={{
                  name: "Water4OtterContact",
                  postUrl: "/intro"
                }}
              />
            </FormWrap>
          </ColWrap>
        </Row>
      </Container>
    </Layout>
  );
};

export default ContactPage;
